import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Seo from '../components/seo'

import ConnectSeoImage from '../assets/images/connect.jpg'

class Connect extends React.Component {
    componentDidMount() {
        const initCurator = () => {
            (function(){
            var i, e, d = document, s = "script";i = d.createElement("script");i.async = 1;
            i.src = "https://cdn.curator.io/published/e2396545-0f58-4a28-a3f2-71481c7c082e.js";
            e = d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
            })()
        }
        initCurator()
    }

    render() {
        return (
            <StaticQuery
            query={graphql`
                query ConnectMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                            social {
                                facebook {
                                    url
                                }
                                instagramUrl
                                linkedInUrl
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout page='connect'>
                    <Seo title={`Connect with Us | ${data.site.siteMetadata.title}`} description={data.site.siteMetadata.description} image={ConnectSeoImage} keywords={[``]} />
                    <Banner title='Connect with Us' subTitle='' titleColor='#f58220' subTitleColor='#f58220' image={ConnectSeoImage} />
                    <section id='connect' className='reverse'>
                        <Container>
                            <Row>
                                <Col className='display-flex' xs='12' sm='12' md='12' lg='12'>
                                    <Col className='column' xs='12' sm='4' md='4' lg='4'>
                                        <h3>Facebook</h3>
                                        <iframe className='facebook-feed' title='facebook-feed' src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FCalli-Law-LLC-201705913652516&tabs=timeline&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId=167680863312844" scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                    </Col>
                                    <Col className='column' xs='12' sm='4' md='4' lg='4'>
                                        <h3>Instagram</h3>
                                        <div id="curator-feed-calli-law-llc-layout"></div>
                                        <script type="text/javascript">
                                        </script>
                                    </Col>
                                    <Col className='column' xs='12' sm='4' md='4' lg='4'>
                                        <h3>LinkedIn</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        <button className='btn btn-secondary center-block'><a href={data.site.siteMetadata.social.linkedInUrl}>Follow Us</a></button>
                                    </Col>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default Connect